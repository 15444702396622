<template>
    <InlinePreloader v-if="isLoading" message="Loading..."></InlinePreloader>
    <div v-else class="quoting-portal-setup" :class="{'tw-animate-pulse tw-transition-all  tw-duration-300 tw-pointer-events-none': (requestCustomBrandingStatus.isLoading || createInitialSetupStatus.isLoading || updateQuotingPortalStatusStatus.isLoading)}">
        <Form :initialValues="{ quotingPortalUrl: data.subdomain_name, leadsNotificationEmail: data.leads_notification_email, defaultPricelist: data.pricelist_header_id }"
              v-slot="{ errors }"
              @submit="onSubmit" class="tw-max-w-xl">
            <div :class="{'tw-opacity-50 tw-pointer-events-none tw-cursor-not-allowed': !isActive}">
                <label class="form__label tw-mt-0">
                    <span class="form__labelTitle">Quoting Portal URL</span>
                    <div class="tw-flex tw-items-center tw-gap-2">
                        <Field
                        as="input"
                        type="text"
                        rules="min:3|required|string_without_whitespace|max:128"
                        class="form__input tw-w-auto"
                        placeholder="URL"
                        name="quotingPortalUrl"
                        label="quoting portal url"
                        value=""
                        :disabled="!isInitialSetup"
                        />
                        <span class="tw-text-gray-500 tw-italic">.{{qpDomain}}</span>
                      <span v-if="!isInitialSetup"
                            @click="copyUrl"
                            class="tw-bg-gray-400 hover:tw-bg-opacity-90 tw-text-white tw-px-2 tw-rounded-md tw-flex tw-items-center tw-leading-none tw-text-xs tw-py-1 tw-cursor-pointer">{{ isCopied? 'COPIED': 'COPY' }}</span>
                    </div>

                    <span class="form__error">{{ errors.quotingPortalUrl }}</span>
                </label>

              <label class="form__label">
                <span class="form__labelTitle">Quoting Portal Price List</span>
                <Field as="select" class="form__input form__select"
                       name="defaultPricelist"
                       label="default price list"
                       :rules="{required: true}"

                >
                  <option
                    v-for="(item, index) in pricelists"
                    :value="item.id"
                    :key="index"
                  >{{item.name}}
                  </option>
                </Field>
                <span class="form__error">{{ errors.defaultPricelist }}</span>
                <p class="tw-mb-2">Available to choose any active price lists without date end applied for all customers</p>

              </label>


              <template v-if="!isInitialSetup">
              <label class="form__label">
                    <span class="form__labelTitle">New Leads Notification Email</span>
                    <Field
                    as="input"
                    type="text"
                    :rules="{ required:false,email: true }"
                    class="form__input"
                    placeholder="Email address"
                    name="leadsNotificationEmail"
                    label="Leads notification email"
                    value=""
                    />

                    <span class="form__error">{{ errors.leadsNotificationEmail }}</span>
                </label>
                <Form v-slot="{ errors: errorWebsite }" :initialValues=" {website: data.website} " @submit="handleRequestCustomBranding">
                    <label class="form__label">
                        <span class="form__labelTitle">White Labeling</span>
                        <p class="tw-mb-2">Please provide your website URL, if you have one and click 'Request custom branding' button</p>
                        <div class="tw-flex tw-flex-col tw-gap-2">
                        <Field
                        ref="website"
                        as="input"
                        type="text"
                        :rules="{ url: /^(?:http|https):\/\/(?:www\.)?([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[\.]?)+\.[a-z]{2,6}(?:\/.*)?$/ }"
                        class="form__input"
                        placeholder="https://yourwebsite.com"
                        name="website"
                        label="Website"
                        value=""
                        />
                        <button type="submit" class="tw-btn-tertiary-success tw-px-0 tw-w-max tw-whitespace-nowrap">Request custom branding</button>
                        </div>

                        <span class="form__error" v-if="errorWebsite.website">The Website field is not a valid URL. Please enter the full URL, including http:// or https:// (e.g., https://www.yourwebsite.com)</span>
                    </label>
                </Form>


              <label class="form__label">
                <span class="form__labelTitle">Maximum Travel Distance from Primary/Trading Address (km)</span>
<!--                @click="showSelectSuburbModalForMaxTravelDistance = true"-->
                <Field
                  as="input"
                  type="text"
                  :rules="{decimal:0,numeric: true }"
                  class="form__input"
                  placeholder="maximum travel distance"
                  name="maximum_travel_distance"
                  label="Maximum travel distance (Km)"
                  value=""
                  v-model="data.maximum_travel_distance"
                />

                <span class="form__error">{{ errors.maximum_travel_distance }}</span>
              </label>
              </template>
            </div>
            <div class="md:tw-flex-row tw-flex tw-flex-col tw-gap-2">
                <button type="submit" v-if="!isInitialSetup" :disabled="!isActive" class="tw-btn-primary-success" :class="{'tw-hidden': !isActive}">SAVE SETTINGS</button>
                <button type="submit" v-if="isInitialSetup" class="tw-btn-primary-success tw-w-50">Enable Quoting Portal</button>
                <button type="button" v-else @click="handleEnableDisableAction" :class="[isActive? 'tw-btn-secondary-success': 'tw-btn-primary-success' ]">{{ isActive? 'DISABLE': 'ENABLE' }} QUOTING PORTAL</button>
            </div>
        </Form>

        <div class="tw-mt-4" v-if="!isInitialSetup && isActive">
            <div class="tw-max-w-xl">
                <ul v-cloak class="tabs tw-justify-start tw-mb-1 tw-gap-1 tw-items-start">
                    <li class="tabs__item tw-max-w-xs tw-m-0">
                        <button
                          :disabled="isInitialSetup"
                            @click="tabName = 'serviceArea'"
                            class="tabs__link tw-uppercase"
                            :class="{'tabs__link_active': tabName == 'serviceArea'}"
                        >
                            Area of service
                        </button>
                    </li>
                    <li class="tabs__item  tw-max-w-xs tw-m-0">
                        <button
                          :disabled="isInitialSetup"
                            @click="tabName = 'calendar'"
                            class="tabs__link tw-uppercase"
                            :class="{tabs__link_active: tabName == 'calendar'}"
                        >
                            calendar
                        </button>
                    </li>
                </ul>
            </div>


            <keep-alive>
                <ServiceArea v-if="tabName == 'serviceArea'"></ServiceArea>
                <HolidayCalendar v-else></HolidayCalendar>
            </keep-alive>
        </div>

      <SelectSuburbModal v-model:show="showSelectSuburbModalForMaxTravelDistance"/>
    </div>
</template>

<script setup>

import { computed, ref } from '@vue/reactivity'
import useGetQuotingPortalSetup from '../../../hooks/quoting-portal-setup/useGetQuotingPortalSetup';
import useGetPricelists from '../../../hooks/price-lists/useGetPricelists';
import useGetPricelistsForQPSetup from '../../../hooks/price-lists/useGetPricelistsForQPSetup'
import useRequestCustomBranding from '../../../hooks/quoting-portal-setup/useRequestCustomBranding';
import useCreateInitialSetup from '../../../hooks/quoting-portal-setup/useCreateInitialSetup';
import useUpdatePortal from '../../../hooks/quoting-portal-setup/useUpdatePortal';
import useUpdateQuotingPortalStatus from '../../../hooks/quoting-portal-setup/useUpdateQuotingPortalStatus';
import InlinePreloader from '../../../components/parts/inlinePreloader.vue';
import _ from 'lodash'
import ServiceArea from './ServiceArea.vue';
import HolidayCalendar from './HolidayCalendar.vue';

const { data, isLoading } = useGetQuotingPortalSetup()
const { data: pricelists, isLoading: isLoadingPricelists } = useGetPricelistsForQPSetup()
const { requestCustomBranding, requestCustomBrandingStatus } = useRequestCustomBranding()
const { createInitialSetup, createInitialSetupStatus } = useCreateInitialSetup()
const { updatePortal, updatePortalStatus } =  useUpdatePortal()
const { updateQuotingPortalStatus, updateQuotingPortalStatusStatus } = useUpdateQuotingPortalStatus()
import SelectSuburbModal from "./Modals/SelectSuburbModal"
import {watch} from "@vue/runtime-core";
import { useStore } from 'vuex';

const store = useStore()
const showSelectSuburbModalForMaxTravelDistance =ref(false)

const suggestedSubDomain = ref(null)

const isActive = computed(() => {
  return data.value.state !== 'blocked'
})

const isInitialSetup = computed(() => {
  return !!data.value?.suggested_sub_domain
})

const website = ref(null)

const tabName = ref('serviceArea') //serviceArea|calendar

const onSubmit = async (values) => {
  store.commit('preloader/preloader', true)
  if(isInitialSetup.value) {
    await createInitialSetup(values)
  }else {
    try {
      await updatePortal({...values, website: website.value.$el.value})
    } catch (error) {
    }
  }
  store.commit('preloader/preloader', false)
}

const handleRequestCustomBranding = async (values) => {
  const { website } = values
  await requestCustomBranding({
    website
  })
}

const handleEnableDisableAction = async () => {
  await updateQuotingPortalStatus()
}


const isCopied = ref(false)
const qpDomain = process.env.VUE_APP_QUOTING_PORTAL_DOMAIN?? 'quotingportal.com.au'
const copyUrl = () => {
  const url = `https://${data.value.subdomain_name}.${qpDomain}`
  navigator.clipboard.writeText(url)
    .then(() => {
      isCopied.value = true
      setTimeout(() => {
        isCopied.value = false
      }, 3000);
    })
}

watch(() => showSelectSuburbModalForMaxTravelDistance.value,(value) => {

})





</script>

<style scoped>



</style>

